import { render, staticRenderFns } from "./ComedorBecariosList.vue?vue&type=template&id=2067fd91&scoped=true&"
import script from "./ComedorBecariosList.vue?vue&type=script&lang=js&"
export * from "./ComedorBecariosList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2067fd91",
  null
  
)

export default component.exports